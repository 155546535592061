import React, { useEffect, useMemo } from 'react';
import { graphql, PageProps, useStaticQuery } from 'gatsby';
import * as JsSearch from 'js-search';
import Header from '../components/header';
import Layout from '../components/ui/layout';
import Product from '../components/product';
import Button from '../components/ui/button';
import Input from '../components/ui/input';
import Footer from '../components/footer';
import styled from 'styled-components';
import { RelatedProductsGrid } from '../templates/product';
import Filters from '../components/filters';
import screens from '../@style/screens';
import { categoriesCapitalized } from '../data/categories';
import { useQueryParamString } from 'react-use-query-param-string';
import SEO from '../components/seo';

const GetAllProducts = graphql`
  query GetAllProducts {
    allProducts {
      nodes {
        _source {
          product {
            ...ProductsFields
          }
        }
      }
    }
  }
`;

const StyledHR = styled.hr`
  border-top: 1px solid #00a3e0;
  width: 100%;
  margin: 25px 0 40px 0;
  @media ${screens.laptop} {
    margin: 40px 0 50px 0;
    border-width: 2px;
  }
`;

const ResultsCopy = styled.h3`
  font-size: 17px;
  font-weight: 700;
  @media ${screens.laptop} {
    font-size: 48px;
  }
`;

const ItemsCount = styled.h4`
  font-size: 15px;
  font-weight: 400;
  margin: 5px 0 5px 0;
  @media ${screens.laptop} {
    font-size: 20px;
  }
`;

const search = new JsSearch.Search('omniItemId');
search.indexStrategy = new JsSearch.AllSubstringsIndexStrategy();

type SearchPageProps = PageProps<null, null, { phrase?: string; category?: string }, null>;

const SearchPage: React.FC<SearchPageProps> = ({ location }) => {
  const [phrase, setPhrase] = useQueryParamString('phrase', '');
  const [results, setResults] = React.useState<Queries.ProductsFieldsFragment[]>([]);

  const [selectedCategories, setSelectedCategories] = useQueryParamString(
    'categories',
    location.state?.category ? location.state.category : ''
  );

  const [loadedResults, setLoadedResults] = React.useState(100);

  const { allProducts } = useStaticQuery<Queries.GetAllProductsQuery>(GetAllProducts);

  const filteredProducts = useMemo(
    () =>
      allProducts.nodes
        .filter((product) => product._source?.product)
        .map((productRaw) => productRaw._source?.product!),
    [allProducts.nodes]
  );

  const nonNullCategories = useMemo(
    () =>
      allProducts.nodes
        .filter((product) => product._source?.product?.merchandisingHierarchy?.divisionDescription)
        .map((productRaw) => {
          const division = productRaw._source?.product?.merchandisingHierarchy;
          if (division) {
            return `${division.divisionDescription}|${division.division}`;
          }
          return null;
        }),
    [allProducts.nodes]
  );

  const categoriesRaw = useMemo(() => [...new Set(nonNullCategories)], [nonNullCategories]);

  const categories = categoriesRaw.map((category) => {
    if (category) {
      const [name, id] = category.split('|');
      return {
        name:
          categoriesCapitalized?.[id] ?? name[0].toUpperCase() + name.substring(1).toLowerCase(),
        id,
      };
    }
    return {};
  });

  useEffect(() => {
    if (location.state?.phrase) {
      setPhrase(location.state?.phrase);
    }
  }, []);

  useEffect(() => {
    search.addIndex('description');
    search.addIndex('brand');
    search.addDocuments(filteredProducts);
  }, [filteredProducts]);

  useEffect(() => {
    setResults(search.search(phrase) as Queries.ProductsFieldsFragment[]);
  }, [phrase]);

  const finalData = results.length > 0 ? results : phrase.length > 0 ? results : filteredProducts;

  const resultsPerCategory = finalData.filter((result) => {
    if (selectedCategories.length > 0) {
      return selectedCategories.includes(result.merchandisingHierarchy?.division ?? '');
    }
    return true;
  });

  const selectedCategoriesCopies = selectedCategories
    .split('-')
    .map((categoryId) => categories.find((category) => category.id === categoryId)?.name);

  return (
    <>
      <SEO />
      <Header />
      <Layout>
        <Input
          id="search-input"
          placeholder="Search our 3D product catalog"
          defaultValue={phrase}
          handleOnChange={(newValue: string) => {
            setPhrase(newValue);
          }}
        />
      </Layout>
      <Filters
        items={categories.map((category) => ({
          name: category.name ?? '',
          onClick: () => {
            if (category.id) {
              if (selectedCategories.includes(category.id ?? 'none')) {
                setSelectedCategories(
                  selectedCategories
                    .split('-')
                    .filter((value) => value !== category.id && value !== '')
                    .join('-')
                );
              } else {
                setSelectedCategories(
                  [
                    ...selectedCategories.split('-').filter((value) => value !== ''),
                    category.id,
                  ].join('-')
                );
              }
            }
          },
          selected: selectedCategories.includes(category.id ?? 'none'),
        }))}
      />
      <StyledHR />
      <Layout>
        <ResultsCopy>
          {phrase
            ? `Results for “${phrase}”`
            : selectedCategories.length > 0
            ? selectedCategoriesCopies.join(', ')
            : 'Showing all products'}
        </ResultsCopy>
        <ItemsCount>{resultsPerCategory.length} Products</ItemsCount>
        <RelatedProductsGrid>
          {resultsPerCategory.map(
            (product, index) =>
              index < loadedResults && <Product key={`related-product-${index}`} data={product} />
          )}
        </RelatedProductsGrid>
        {loadedResults < resultsPerCategory.length && (
          <Button onClick={() => setLoadedResults(loadedResults + 100)}>
            Load Next 100 Products
          </Button>
        )}
      </Layout>
      <Footer />
    </>
  );
};

export default SearchPage;
